// Dependencies
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EditText } from 'react-edit-text';
import DatePicker from 'react-date-picker';

// Components
import CountrySelector from '../countriesSelector/countriesSelector';

// Resources
import { currentLanguage } from '../../../../views';
import countriesEN from '../../assets/json/countriesEN.json';
import countriesFR from '../../assets/json/countriesFR.json';

// Stylesheets
import './forms.css';
import 'react-edit-text/dist/index.css';
import 'react-calendar/dist/Calendar.css';
import 'react-date-picker/dist/DatePicker.css';

interface LodgingMissionDetailsProperties {
  ref: React.RefObject<HTMLDivElement>;
  defaultStartDate: string;
  defaultEndDate: string;
  defaultType: string;
  defaultMainAddress: string;
  defaultAdditionalAddress: string;
  defaultPostalCode: string;
  defaultCity: string;
  defaultCountry: string;
}

/**
 * @name lodgingMissionDetails.tsx
 * @implements updateFormsProperties.tsx
 *
 * @path /components/updateForms/lodgingMissionDetails.tsx
 *
 * @description Defines content for update lodging information popup in MissionDetails component
 */
const LodgingMissionDetails: React.FC<LodgingMissionDetailsProperties> = ({
  ref,
  defaultStartDate,
  defaultEndDate,
  defaultType,
  defaultMainAddress,
  defaultAdditionalAddress,
  defaultPostalCode,
  defaultCity,
  defaultCountry,
}) => {
  // Parameter that must be used before each translatable string to apply the good language version
  const { t } = useTranslation();

  const lodgingTypeOptions = [
    { id: 1, value: t('popupStrings.missionDetailsPopupStrings.apartment') },
    { id: 2, value: t('popupStrings.missionDetailsPopupStrings.camping') },
    { id: 3, value: t('popupStrings.missionDetailsPopupStrings.hotel') },
    { id: 4, value: t('popupStrings.missionDetailsPopupStrings.other') },
  ];

  // State variable to store current selected option
  const [countries, setCountries] = useState<any[]>([]);
  const [selectedOptionCountry, setSelectedOptionCountry] = useState<
    string | null
  >(defaultCountry);
  const [selectedOptionLodgingType, setSelectedOptionLodgingType] = useState<
    string | null
  >(defaultType);

  // Setters for dropdown buttons' visibility
  const [isOpenCountry, setIsOpenCountry] = useState(false);
  const [isOpenLodgingType, setIsOpenLodgingType] = useState(false);

  // Popups' references
  const countryRef = useRef<HTMLSelectElement>(null);
  const lodgingTypeRef = useRef<HTMLSelectElement>(null);

  // Is what happening when user performed a click on the country selector
  const handleSelectChangeCountry = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const newSelectedOptionCountry = event.target.value;

    setSelectedOptionCountry(newSelectedOptionCountry);
  };

  useEffect(() => {
    setCountries(['France']);
  }, []);

  const [lodgingStart, setLodgingStart] = useState<string>(defaultStartDate);
  const [lodgingEnd, setLodgingEnd] = useState<string>(defaultEndDate);
  const [lodgingMainAddress, setLodgingMainAddress] =
    useState<string>(defaultMainAddress);
  const [lodgingAdditionalAddress, setLodgingAdditionalAddress] =
    useState<string>(defaultAdditionalAddress);
  const [lodgingPostalCode, setLodgingPostalCode] =
    useState<string>(defaultPostalCode);
  const [lodgingCity, setLodgingCity] = useState<string>(defaultCity);

  const handleLodgingStartDateChange = (date: any) => {
    setLodgingStart(date);
  };

  const handleLodgingEndDateChange = (date: any) => {
    setLodgingEnd(date);
  };

  const handleLodgingTypeChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const newLodgingType = event.target.value;

    setSelectedOptionLodgingType(newLodgingType);
  };

  const handleLodgingMainAddressChange = ({ value }: { value: string }) => {
    setLodgingMainAddress(value);
  };

  const handleLodgingAdditionalAddressChange = ({
    value,
  }: {
    value: string;
  }) => {
    setLodgingAdditionalAddress(value);
  };

  const handleLodgingPostalCodeChange = ({ value }: { value: string }) => {
    setLodgingPostalCode(value);
  };

  const handleLodgingCityChange = ({ value }: { value: string }) => {
    setLodgingCity(value);
  };

  return (
    <div className='detailsPopupContent' ref={ref}>
      {/* Occupancy's period */}
      <p>
        <b>{t('popupStrings.missionDetailsPopupStrings.occupancysPeriod')}</b>
      </p>

      {/* Start date */}
      <div className='missionDetailsStartDate'>
        <p>
          <b>{t('popupStrings.missionDetailsPopupStrings.startDate')}</b>
        </p>
        <DatePicker
          value={lodgingStart || ''}
          className='missionDetailsDatePicker'
          format='dd/MM/yyyy'
          onChange={handleLodgingStartDateChange}
          locale={currentLanguage.toUpperCase().split('-')[0]}
        />
      </div>

      {/* End date */}
      <div className='missionDetailsEndDate'>
        <p>
          <b>{t('popupStrings.missionDetailsPopupStrings.endDate')}</b>
        </p>
        <DatePicker
          value={lodgingEnd || ''}
          className='missionDetailsDatePicker'
          format='dd/MM/yyyy'
          onChange={handleLodgingEndDateChange}
          locale={currentLanguage.toUpperCase().split('-')[0]}
        />
      </div>

      {/* Lodging Type */}
      <div className='missionDetailsLodgingType'>
        <p>
          <b>{t('popupStrings.missionDetailsPopupStrings.lodgingType')}</b>
        </p>
        <div className='lodgingTypeSelectorContainer'>
          <select
            id={'lodgingTypeSelector'}
            ref={lodgingTypeRef}
            className='lodgingTypeSelector'
            onClick={() => setIsOpenLodgingType(!isOpenLodgingType)}
            value={selectedOptionLodgingType || ''}
            onChange={handleLodgingTypeChange}
          >
            <option key='defaultLodgingType' value=''>
              {t('popupStrings.missionDetailsPopupStrings.chooseALodgingType')}
            </option>
            {lodgingTypeOptions.map((option, index) => {
              return (
                <option key={index} value={option.value}>
                  {option.value}
                </option>
              );
            })}
          </select>
        </div>
      </div>

      {/* Address */}
      <p>
        <b>{t('popupStrings.missionDetailsPopupStrings.address')}</b>
      </p>
      <div className='lodgingAddressInformation'>
        <div className='lodgingMainAddress'>
          <EditText
            placeholder={t(
              'popupStrings.missionDetailsPopupStrings.mainAddress'
            )}
            defaultValue={defaultMainAddress}
          />
        </div>
      </div>
      <div className='lodgingAddressInformation'>
        <div className='lodgingAdditionalAddress'>
          <EditText
            placeholder={t(
              'popupStrings.missionDetailsPopupStrings.additionalAddress'
            )}
            defaultValue={defaultAdditionalAddress}
          />
        </div>
        <div className='lodgingPostalCode'>
          <EditText
            placeholder={t(
              'popupStrings.missionDetailsPopupStrings.postalCode'
            )}
            defaultValue={defaultPostalCode}
          />
        </div>
      </div>
      <div className='lodgingAddressInformation'>
        <div className='lodgingCity'>
          <EditText
            placeholder={t('popupStrings.missionDetailsPopupStrings.city')}
            defaultValue={defaultCity}
          />
        </div>
        <CountrySelector
          divClassName='lodgingCountry'
          selectorClassName='lodgingSelectorCountry'
          selectorValue={selectedOptionCountry}
          selectorOnChange={handleSelectChangeCountry}
          countriesList={countries}
        />
      </div>
    </div>
  );
};

export default LodgingMissionDetails;
