// Dependencies
import React from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

// Components
import Header from '../components/header/header';
import Content from '../components/content/content';
import Footer from '../components/footer/footer';
import Missions from '../components/missions/missions';

interface MissionsPageProperties {
  companyId: number;
}

/**
 * @name missionPage.tsx
 *
 * @path /pages/missionPage.tsx
 *
 * @description Defines mission page's content
 */
const MissionPage: React.FC = ({ history }: any) => {
  // Parameter that must be used before each translatable string to apply the good language version
  const { t } = useTranslation();

  const { companyId } = useParams<MissionsPageProperties>();

  return (
    <div className='pages'>
      <Helmet>
        <title>
          {t('missionPageStrings.missionsTitle')}
          {t('helmetString')}
        </title>
      </Helmet>
      <Header />
      <Content
        title={t('missionPageStrings.missionsTitle')}
        homepage={false}
        history={history}
      />
      <Missions companyId={companyId} />
      <Footer />
    </div>
  );
};

export default MissionPage;
