/**
 * Dependencies
 */
import React, { Component, ChangeEvent, Dispatch, SetStateAction } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

/**
 * Resources
 */
import './orderForm.css';

/**
 * ConstructionCardProps interface defines the structure of props
 * expected by the ConstructionCard component.
 */
interface ConstructionCardProps extends WithTranslation {
  setCount: Dispatch<SetStateAction<number>>;
}

/**
 * ConstructionCard component displays a part of OrderForm,
 * that gives user a choice between buy new construction cards,
 * or modify its existing cards.
 */
class ConstructionCard extends Component<ConstructionCardProps> {
  render() {
    const { setCount } = this.props;

    /**
     * Extract the `t` function from `useTranslation` hook.
     */
    const { t } = this.props;

    const newCardOptions = [
      {
        id: '0',
        label: t('orderPageStrings.missionTypeOptions.haveConstructionCard'),
      },

      {
        id: '1',
        label: t('orderPageStrings.missionTypeOptions.haveNotConstructionCard'),
      },
    ];

    function handleIncrement(): void {
      setCount((prevCount) => prevCount + 1);
    }

    function handleNewCardOptionsChange(
      event: ChangeEvent<HTMLInputElement>
    ): void {
      window.sessionStorage.setItem('selectedNewCardOption', event.target.id);
      handleIncrement();
    }

    return (
      <div className='orderConstructionCard'>
        <div className='orderConstructionCardOptions'>
          <pre>
            <p id='obligatoire'>* </p>
          </pre>
          <pre>
            <p>
              {t('orderPageStrings.missionTypeOptions.needConstructionCard')}
            </p>
          </pre>
        </div>
        <div className='orderConstructionCardOptions'>
          {newCardOptions.map((option) => (
            <div key={option.id} className='orderDetailsRadioButton'>
              <label key={option.id}>
                <input
                  id={option.id}
                  type='radio'
                  name='options'
                  onChange={handleNewCardOptionsChange}
                  checked={
                    window.sessionStorage.getItem('selectedNewCardOption') ==
                    option.id
                  }
                />
                {option.label}
              </label>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default withTranslation()(ConstructionCard);
