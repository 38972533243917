/**
 * Dependencies.
 */
import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

/**
 * Resources.
 */
import './orderForm.css';
import 'react-calendar/dist/Calendar.css';
import 'react-date-picker/dist/DatePicker.css';
import { currentLanguage } from '../../../../views';
import DatePicker from 'react-date-picker';
import AddressBlock from '../addressBlock/addressBlock';

/**
 * LodgingFormProps interface defines the structure of props
 * expected by the LodgingForm component.
 */
interface LodgingFormProps extends WithTranslation {
  startDate: string;
  endDate: string;
  lodgingOption: string;
  handleStartDateChange: ({}) => void;
  handleEndDateChange: ({}) => void;
  handleLodgingOptionChange: ({}) => void;
}

/**
 * LodgingForm component displays a part of OrderForm,
 * that gives user possibility to precise the place where
 * employees will be living.
 */
class LodgingForm extends Component<LodgingFormProps> {
  render() {
    const {
      startDate,
      endDate,
      lodgingOption,
      handleStartDateChange,
      handleEndDateChange,
      handleLodgingOptionChange,
    } = this.props;

    /**
     * Extract the `t` function from `useTranslation` hook.
     */
    const { t } = this.props;

    let lodgingOptions = [
      { id: 1, value: t('popupStrings.missionDetailsPopupStrings.apartment') },
      { id: 2, value: t('popupStrings.missionDetailsPopupStrings.camping') },
      { id: 3, value: t('popupStrings.missionDetailsPopupStrings.hotel') },
      { id: 4, value: t('popupStrings.missionDetailsPopupStrings.other') },
    ];

    let mainAddress: string =
      window.sessionStorage.getItem('lodgingMainAddress');
    let additionalAddress: string = window.sessionStorage.getItem(
      'lodgingAdditionalAddress'
    );
    let postalCode: string = window.sessionStorage.getItem('lodgingPostalCode');
    let city: string = window.sessionStorage.getItem('lodgingCity');
    let country: string = window.sessionStorage.getItem('lodgingCountry');

    function handleMainAddressChange(value: string) {
      window.sessionStorage.setItem('lodgingMainAddress', value);
    }

    function handleAdditionalAddressChange(value: string) {
      window.sessionStorage.setItem('lodgingAdditionalAddress', value);
    }

    function handlePostalCodeChange(value: string) {
      window.sessionStorage.setItem('lodgingPostalCode', value);
    }

    function handleCityChange(value: string) {
      window.sessionStorage.setItem('lodgingCity', value);
    }

    function handleCountryChange(value: string) {
      window.sessionStorage.setItem('lodgingCountry', value);
    }

    return (
      <div className='orderFormLodging'>
        <i>{t('orderPageStrings.lodging.details')}</i>
        <div className='lodgingStartDate'>
          <p>
            <b>{t('popupStrings.missionDetailsPopupStrings.startDate')}</b>
          </p>
          <DatePicker
            value={startDate || ''}
            className='lodgingDatePickerStart'
            format='dd/MM/yyyy'
            minDate={new Date()}
            clearIcon={null}
            onChange={handleStartDateChange}
            locale={currentLanguage.toUpperCase().split('-')[0]}
          />
        </div>
        <div className='lodgingEndDate'>
          <p>
            <b>{t('popupStrings.missionDetailsPopupStrings.endDate')}</b>
          </p>
          <DatePicker
            value={endDate || ''}
            className='lodgingDatePickerEnd'
            format='dd/MM/yyyy'
            minDate={startDate != null ? new Date(startDate) : new Date()}
            clearIcon={null}
            onChange={handleEndDateChange}
            locale={currentLanguage.toUpperCase().split('-')[0]}
          />
        </div>
        <div className='lodgingType'>
          <p>
            <b>{t('popupStrings.missionDetailsPopupStrings.lodgingType')}</b>
          </p>
          <div className='lodgingSelectorContainer'>
            <select
              className='lodgingSelector'
              value={lodgingOption}
              onChange={handleLodgingOptionChange}
            >
              <option key='defaultLodgingType' value=''>
                {t(
                  'popupStrings.missionDetailsPopupStrings.chooseALodgingType'
                )}
              </option>
              {lodgingOptions.map((option) => {
                return (
                  <option key={option.id} value={option.id}>
                    {option.value}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <AddressBlock
          divClassName='lodgingAddress'
          title={t('orderPageStrings.lodging.address')}
          initialMainAddress={mainAddress}
          initialAdditionalAddress={additionalAddress}
          initialPostalCode={postalCode}
          initialCity={city}
          initialCountry={country}
          onSaveMainAddress={handleMainAddressChange}
          onSaveAdditionalAddress={handleAdditionalAddressChange}
          onSavePostalCode={handlePostalCodeChange}
          onSaveCity={handleCityChange}
          onSaveCountry={handleCountryChange}
        />
      </div>
    );
  }
}

export default withTranslation()(LodgingForm);
