/**
 * Dependencies.
 */
import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

/**
 * Resources.
 */
import './orderForm.css';
import PaymentPage from '../payzen/payementPage';

/**
 * PaymentMethodProps interface defines the structure of props
 * expected by the PaymentMethod component.
 */
interface PaymentMethodProps extends WithTranslation {}

/**
 * PaymentMethodState interface defines the structure of props
 * expected by the PaymentMethod component.
 */
interface PaymentMethodState {
  showPaymentForm: boolean;
}

/**
 * PaymentMethod component displays a part of OrderForm,
 * that gives user possibility to chose a method of payment
 * for his new mission
 */
class PaymentMethod extends Component<PaymentMethodProps, PaymentMethodState> {
  constructor(props) {
    super(props);
    // Initialize state
    this.state = {
      showPaymentForm: false,
    };

    this.showPaymentForm = this.showPaymentForm.bind(this);
  }

  showPaymentForm() {
    this.setState((prevState) => ({
      showPaymentForm: !prevState.showPaymentForm,
    }));
  }

  reload() {
    window.location.reload();
  }

  render() {
    /**
     * Extract the `t` function from `useTranslation` hook.
     */
    const { t } = this.props;

    return (
      <div className='orderFormPaymentMethod'>
        {!this.state.showPaymentForm && (
          <i>{t('orderPageStrings.paymentMethod.details')}</i>
        )}
        <div className='paymentMethodButtonsContainer'>
          {this.state.showPaymentForm ? (
            <div>
              <button className='returnButton' onClick={this.reload}>
                ←
              </button>
              <PaymentPage />
            </div>
          ) : (
            <>
              <button className='paymentButton1' onClick={this.showPaymentForm}>
                {t('orderPageStrings.paymentMethod.payByCard')}
              </button>
              <button className='paymentButton2'>
                {t('orderPageStrings.paymentMethod.payByBankTransfer')}
              </button>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation()(PaymentMethod);
