// Dependencies
import React from 'react';

// Interfaces
import MissionTypeOptionProperties from '../../interfaces/missionTypeOptionProperties';

// Resources
import tooltip from '../../assets/images/logos/tooltip.png';

// Stylesheets
import 'tippy.js/dist/tippy.css';
import './orderForm.css';

/**
 * @name missionTypeOption.tsx
 *
 * @path /components/orderForm/missionTypeOption.tsx
 *
 * @description Defines structure for mission option
 */
const MissionTypeOption: React.FC<MissionTypeOptionProperties> = ({
  title,
  sessionStorageConst,
  optionsList,
  tooltipReference,
  handleChange,
  inputName,
}) => {
  return (
    <div className='orderDetailsMissionType'>
      <pre>
        <p id='obligatoire'>* </p>
      </pre>
      <pre>
        <p>{title}</p>
      </pre>
      {optionsList.map((option) => (
        <div key={option.id} className='orderDetailsRadioButton'>
          <label key={option.id}>
            <input
              type='radio'
              name={inputName}
              value={option.id}
              checked={sessionStorageConst == option.id}
              onChange={handleChange}
            />
            {option.label}
          </label>
        </div>
      ))}
      <img
        src={tooltip}
        alt='tooltipButton'
        className='tooltip'
        ref={tooltipReference}
      />
    </div>
  );
};

export default MissionTypeOption;
