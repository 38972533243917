import paths from './paths';
import Homepage from 'spw/src/pages/homepage';
import CompaniesPage from 'spw/src/pages/companiesPage';
import CompanyPage from 'spw/src/pages/companyPage';
import OrderPage from 'spw/src/pages/orderPage';
import EmployeesPage from 'spw/src/pages/employeesPage';
import EmployeeDetailsPage from 'spw/src/pages/employeeDetailsPage';
import MissionPage from 'spw/src/pages/missionPage';
import MissionDetailsPage from 'spw/src/pages/missionDetailsPage';
import InspectionDetailsPage from 'spw/src/pages/inspectionDetailsPage';

export default [
  {
    private: true,
    path: paths.spwHome,
    component: Homepage,
    exact: true,
    authorizedRoles: [],
  },
  {
    private: true,
    path: paths.spwCompanies,
    component: CompaniesPage,
    exact: true,
    authorizedRoles: ['operator', 'admin'],
  },
  {
    private: true,
    path: paths.spwCompany,
    component: CompanyPage,
    exact: true,
    authorizedRoles: [],
  },
  {
    private: true,
    path: paths.spwOrder,
    component: OrderPage,
    exact: true,
    authorizedRoles: [],
  },
  {
    private: true,
    path: paths.spwEmployees,
    component: EmployeesPage,
    exact: true,
    authorizedRoles: [],
  },
  {
    private: true,
    path: paths.spwEmployeesDetails,
    component: EmployeeDetailsPage,
    exact: true,
    authorizedRoles: [],
  },
  {
    private: true,
    path: paths.spwMissions,
    component: MissionPage,
    exact: true,
    authorizedRoles: [],
  },
  {
    private: true,
    path: paths.spwMissionsDetails,
    component: MissionDetailsPage,
    exact: true,
    authorizedRoles: [],
  },
  {
    private: true,
    path: paths.spwInspectionDetails,
    component: InspectionDetailsPage,
    exact: true,
    authorizedRoles: [],
  },
];
