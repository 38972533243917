// Dependencies
import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { selectors } from 'store';

// Components
import Header from '../components/header/header';
import Footer from '../components/footer/footer';
import Content from '../components/content/content';
import Dashboard from '../components/dashboard/dashboard';
import Popup from '../components/popup/popup';
import CreateCompany from '../components/createForms/createCompany';
import Loader from 'components/Loader';

// Stylesheets
import '../index.css';

/**
 * @name homepage.tsx
 *
 * @path /pages/homepage.tsx
 *
 * @description Defines homepage's content
 */

const Homepage: React.FC = ({ history, user }: any) => {
  // Use like this {t('string to translate')}
  const { t } = useTranslation();

  const [popupIsVisible, setPopupIsVisible] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const popupRef = useRef<HTMLDivElement>(null);

  const handleClickClose = () => {
    window.location.href = `/`;
  };

  const checkIfUserHasOneCompany = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/userHasOneCompany`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ id: user.id }),
        }
      );

      const data = await response.json();
      return JSON.stringify(data.exists);
    } catch (error) {
      console.log('Erreur', error);
      setIsLoading(false);
    }
  };

  const checkContext = async () => {
    if (user.operator || user.admin) {
      setPopupIsVisible(false);
    } else {
      const result = await checkIfUserHasOneCompany();

      if (result === 'true') {
        setPopupIsVisible(false);
      } else {
        setPopupIsVisible(true);
      }
    }

    setIsLoading(false);
  };

  useEffect(() => {
    checkContext();
  }, []);

  return (
    <>
      {(isLoading && <Loader />) ||
        (!isLoading && (
          <>
            <div className='pages'>
              <Helmet>
                <title>
                  {t('homeStrings.homeHelmet')}
                  {t('helmetString')}
                </title>
              </Helmet>

              {!popupIsVisible && (
                <>
                  <Header />
                  <Content
                    title={t('dashboardStrings.dashboard')}
                    homepage={true}
                    history={history}
                  />
                  <Dashboard />
                  <Footer />
                </>
              )}
            </div>
            {popupIsVisible && (
              <Popup
                myRef={popupRef}
                title={t('companyPageStrings.welcome')}
                onClickClose={handleClickClose}
              >
                {<CreateCompany />}
              </Popup>
            )}
          </>
        ))}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: selectors.auth.userSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Homepage);
