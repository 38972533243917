import React, { useState, useEffect } from 'react';
import KRGlue from '@lyracom/embedded-form-glue';
import { currentLanguage } from 'views';

import './paymentPage.css';

const PaymentPage: React.FC = () => {
  const [message, setMessage] = useState('');

  useEffect(() => {
    async function setupPaymentForm() {
      const endpoint = 'https://api.payzen.eu';
      const publicKey =
        '25926341:testpublickey_GZvGSQdFkIQVA9KMBeKvzaxwYlPqG6s6Jg5guWy7S2rcv';
      let formToken = 'DEMO-TOKEN-TO-BE-REPLACED';

      try {
        const res = await fetch(
          `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/createPayment`,
          {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              paymentConf: {
                amount: window.sessionStorage
                  .getItem('totalPrice')
                  .includes('.')
                  ? Number(
                      window.sessionStorage
                        .getItem('totalPrice')
                        .replace('.', '')
                    )
                  : Number(window.sessionStorage.getItem('totalPrice') + '00'),
                currency: 'EUR',
              },
            }),
          }
        );
        formToken = await res.text();

        const { KR } = await KRGlue.loadLibrary(
          endpoint,
          publicKey
        ); /* Load the remote library */

        await KR.setFormConfig({
          /* set the minimal configuration */
          formToken: formToken,
          'kr-language':
            currentLanguage == 'fr'
              ? 'fr-FR'
              : 'en-UK' /* to update initialization parameter */,
        });

        await KR.onSubmit(async (paymentData) => {
          const response = await fetch(
            `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/validatePayment`,
            {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(paymentData),
            }
          );
          if (response.status === 200) setMessage('Payment successful!');
          return false; // Return false to prevent the redirection
        });

        await KR.renderElements(
          '#myPaymentForm'
        ); /* Render the payment form into myPaymentForm div */
      } catch (error) {
        setMessage(error + ' (see console for more details)');
      }
    }

    setupPaymentForm();
  }, []);

  return (
    <div className='form'>
      <div className='container'>
        <div id='myPaymentForm'>
          <div className='kr-smart-form' kr-card-form-expanded></div>
        </div>
        <div data-test='payment-message'>{message}</div>
      </div>
    </div>
  );
};

export default PaymentPage;
