export default {
  spwHome: '/spw',
  spwCompanies: '/spw/companies',
  spwCompany: '/spw/company/details/:companyId',
  spwOrder: '/spw/order',
  spwEmployees: '/spw/company/employees/:companyId',
  spwEmployeesDetails: '/spw/company/employees/:companyId/details/:employeeId',
  spwMissions: '/spw/missions/:companyId',
  spwMissionsDetails: '/spw/missions/:companyId/details/:reference',
  spwInspectionDetails:
    '/spw/missions/:companyId/details/:reference/inspection',
};
