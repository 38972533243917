// Dependencies
import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

// Components
import Header from '../components/header/header';
import Content from '../components/content/content';
import Footer from '../components/footer/footer';
import MissionDetails from '../components/missions/missionDetails';

/**
 * @name missionDetailsPage.tsx
 *
 * @path /pages/missionDetailsPage.tsx
 *
 * @description Defines mission details page's content
 */
const MissionDetailsPage: React.FC = ({ history }: any) => {
  // Parameter that must be used before each translatable string to apply the good language version
  const { t } = useTranslation();

  return (
    <div className='pages'>
      <Helmet>
        <title>
          {t('missionPageStrings.missionsTitle')}
          {t('helmetString')}
        </title>
      </Helmet>

      <Header />
      <Content
        title={t('missionDetailsPageStrings.missionDetailsTitle')}
        homepage={false}
        history={history}
      />
      <MissionDetails />
      <Footer />
    </div>
  );
};

export default MissionDetailsPage;
