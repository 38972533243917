// Dependencies
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Interfaces
import OrderProperties from '../../interfaces/orderProperties';

// Resources
import downArrow from '../../assets/images/logos/downArrow.png';

// Stylesheets
import './orderForm.css';

/**
 * @name informationDocuments.tsx
 *
 * @path /components/orderForm/informationDocuments.tsx
 *
 * @description Defines a checkbox list to choose information documents to join at the new mission
 */
const InformationDocuments: React.FC<OrderProperties> = ({ setCount }) => {
  // Parameter that must be used before each translatable string to apply the good language version
  const { t } = useTranslation();

  // Variables
  const [checkedLanguagesArray, setCheckedLanguagesArray] = useState<string[]>(
    []
  );

  useEffect(() => {
    const savedSelection = window.sessionStorage.getItem(
      'checkedLanguagesArray'
    );
    if (savedSelection) {
      setCheckedLanguagesArray(JSON.parse(savedSelection));
    }
  }, []);

  // Reloader
  const handleIncrement = () => {
    setCount((prevCount) => prevCount + 1);
  };

  const handleClickCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    let updatedSelection;
    let code = event.target.id;
    if (checkedLanguagesArray.includes(code)) {
      updatedSelection = checkedLanguagesArray.filter((c) => c !== code);
    } else {
      updatedSelection = [...checkedLanguagesArray, code];
    }
    setCheckedLanguagesArray(updatedSelection);
    window.sessionStorage.setItem(
      'checkedLanguagesArray',
      JSON.stringify(updatedSelection)
    );

    handleIncrement();
  };

  const leftOptions = [
    {
      id: 'en',
      label: t('orderPageStrings.informationDocuments.en'),
      ref: useRef<HTMLInputElement>(null),
    },
    {
      id: 'cz',
      label: t('orderPageStrings.informationDocuments.cz'),
      ref: useRef<HTMLInputElement>(null),
    },
    {
      id: 'it',
      label: t('orderPageStrings.informationDocuments.it'),
      ref: useRef<HTMLInputElement>(null),
    },
    {
      id: 'pt',
      label: t('orderPageStrings.informationDocuments.pt'),
      ref: useRef<HTMLInputElement>(null),
    },
    {
      id: 'ar',
      label: t('orderPageStrings.informationDocuments.ar'),
      ref: useRef<HTMLInputElement>(null),
    },
  ];

  const rightOptions = [
    {
      id: 'bg',
      label: t('orderPageStrings.informationDocuments.bg'),
      ref: useRef<HTMLInputElement>(null),
    },
    {
      id: 'de',
      label: t('orderPageStrings.informationDocuments.de'),
      ref: useRef<HTMLInputElement>(null),
    },
    {
      id: 'es',
      label: t('orderPageStrings.informationDocuments.es'),
      ref: useRef<HTMLInputElement>(null),
    },
    {
      id: 'pl',
      label: t('orderPageStrings.informationDocuments.pl'),
      ref: useRef<HTMLInputElement>(null),
    },
    {
      id: 'ro',
      label: t('orderPageStrings.informationDocuments.ro'),
      ref: useRef<HTMLInputElement>(null),
    },
  ];

  return (
    <div className='informationDocumentsContainer'>
      <pre>
        <p>{t('orderPageStrings.informationDocuments.title')}</p>
      </pre>
      <div className='responsiveAlignment'>
        <div className='informationDocumentScroller'>
          <label key='fr'>
            <input
              type='checkbox'
              key='fr'
              id='fr'
              checked={true}
              onChange={() => {
                return;
              }}
            />
            {t('orderPageStrings.informationDocuments.fr')}
          </label>
          <div className='choosableOptions'>
            {leftOptions.map((option) => (
              <label key={option.id}>
                <input
                  type='checkbox'
                  id={option.id}
                  key={option.id}
                  ref={option.ref}
                  checked={checkedLanguagesArray.includes(option.id)}
                  onChange={handleClickCheckbox}
                />
                {option.label}
              </label>
            ))}
          </div>
          <div className='choosableOptions'>
            {rightOptions.map((option) => (
              <label key={option.id}>
                <input
                  type='checkbox'
                  id={option.id}
                  key={option.id}
                  ref={option.ref}
                  checked={checkedLanguagesArray.includes(option.id)}
                  onChange={handleClickCheckbox}
                />
                {option.label}
              </label>
            ))}
          </div>
        </div>
        <img
          src={downArrow}
          alt='downArrow'
          className='informationDocumentsDownArrow'
        />
      </div>
    </div>
  );
};

export default InformationDocuments;
