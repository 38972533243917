// Dependencies
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-date-picker';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { selectors } from 'store';

// Components
import EditButton from '../editButton/editButton';
import LodgingMissionDetails from '../updateForms/lodgingMissionDetails';
import Popup from '../popup/popup';
import CreateInspection from '../createForms/createInspection';
import Loader from 'components/Loader';

// Interfaces
import MissionDetailsProperties from '../../interfaces/missionDetailsProperties';

// Resources
import addLogo from '../../assets/images/logos/add.png';
import { currentLanguage } from 'views';
import saveLogo from '../../assets/images/logos/save.png';

// Stylesheets
import './missions.css';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

/**
 * @name missionDetails.tsx
 *
 * @path /components/missions/missionDetails.tsx
 *
 * @description Defines mission details structure
 */
const MissionDetails: React.FC = ({ user }: any) => {
  // Employee id to get all datas about it by requests
  const { id } = useParams<MissionDetailsProperties>();
  const { reference } = useParams<MissionDetailsProperties>();

  // Parameter that must be used before each translatable string to apply the good language version
  const { t } = useTranslation();
  // Variables
  const numbers = Array.from({ length: 999 }, (_, index) => index + 1);
  const numberOfEmployeesSelectorRef = useRef<HTMLSelectElement>(null);
  const [isOpenEmployees, setIsOpenEmployees] = useState(false);

  // Inspection popup
  const [inspection, setInspection] = useState<boolean>(false);
  const [paid, setPaid] = useState<boolean>(false);
  const [inspectionPopupVisible, setInspectionPopupVisible] =
    useState<boolean>(false);
  const inspectionPopupRef = useRef<HTMLDivElement>(null);

  const handleClickGoToInspection = () => {
    window.location.href = `/spw/missions/details/${id}/${reference}/inspection`;
  };

  const handleClickAddInspection = () => {
    setInspectionPopupVisible(!inspectionPopupVisible);
  };

  const handleClickCloseInspectionPopup = () => {
    setInspectionPopupVisible(false);
  };

  // Popups' references
  const childRefGlobal = useRef<HTMLDivElement>(null);
  const childRefEmployees = useRef<HTMLDivElement>(null);
  const childRefLodging = useRef<HTMLDivElement>(null);
  const globalInformationButtonRef = useRef<HTMLButtonElement>(null);
  const employeesButtonRef = useRef<HTMLButtonElement>(null);
  const lodgingButtonRef = useRef<HTMLButtonElement>(null);
  const globalInformationEditPopupRef = useRef<HTMLDivElement>(null);
  const employeesEditPopupRef = useRef<HTMLDivElement>(null);
  const lodgingEditPopupRef = useRef<HTMLDivElement>(null);

  // Manage popups' visibility
  const [
    globalInformationEditPopupIsVisible,
    setGlobalInformationEditPopupIsVisible,
  ] = useState<boolean>(false);
  const [employeesEditPopupIsVisible, setEmployeesEditPopupIsVisible] =
    useState<boolean>(false);
  const [lodgingEditPopupIsVisible, setLodgingEditPopupIsVisible] =
    useState<boolean>(false);
  // Defines the action that must be executed when user performed a click on global information edit button
  const handleClickEditGlobalInformationButton = () => {
    setGlobalInformationEditPopupIsVisible(
      !globalInformationEditPopupIsVisible
    );
  };

  // Defines the action that must be performed when the user clicks outside of the component
  const handleOutsideClickNbEmployees = (event: MouseEvent) => {
    if (
      numberOfEmployeesSelectorRef.current &&
      !numberOfEmployeesSelectorRef.current.contains(event.target as Node)
    ) {
      setIsOpenEmployees(false);
    }
  };

  // Configuration of page's current state when handleOutsideClick is performed
  useEffect(() => {
    document.addEventListener('click', handleOutsideClickNbEmployees);

    return () => {
      document.removeEventListener('click', handleOutsideClickNbEmployees);
    };
  }, []);

  // Defines the action that must be executed when user performed a click on employees edit button
  const handleClickEditEmployeesButton = () => {
    setEmployeesEditPopupIsVisible(!employeesEditPopupIsVisible);
  };

  // Defines the action that must be executed when user performed a click on lodging edit button
  const handleClickEditLodgingButton = () => {
    setLodgingEditPopupIsVisible(!lodgingEditPopupIsVisible);
  };

  // Popups' references
  const addButtonRef = useRef<HTMLButtonElement>(null);

  // State to hold the list of employees
  const [employees, setEmployees] = useState<string[]>([]);
  const adminOrOperator = user.admin || user.operator;

  const addEmployee = () => {
    setEmployees([...employees, '']);
  };

  const updatePersonInCharge = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/updateMission/updatePersonInCharge`,
        {
          method: 'PATCH',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            id: reference,
            personInCharge: user.email.split('@')[0],
          }),
        }
      );

      const result = await response.json();

      return result.data;
    } catch (error) {
      console.log('Erreur', error);
    }
  };

  const handleClickTakeInCharge = () => {
    updatePersonInCharge();
    window.location.reload();
  };

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isErrorHidden, setIsErrorHidden] = useState<boolean>(true);
  const [sector, setSector] = useState<string>();
  const [from, setFrom] = useState<string>();
  const [to, setTo] = useState<string>();
  const [type, setType] = useState<string>();
  const [numberOfEmployees, setNumberOfEmployees] = useState<string>();
  const [start, setStart] = useState<string>();
  const [end, setEnd] = useState<string>();
  const [personInCharge, setPersonInCharge] = useState<string>();
  const [lodgingId, setLodgingId] = useState<string>();

  const handleStartDateChange = (date: any) => {
    setStart(date);
  };

  const handleEndDateChange = (date: any) => {
    setEnd(date);
  };

  const handleNumberOfEmployeesChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const newSelectedOptionNumber = event.target.value;

    setNumberOfEmployees(newSelectedOptionNumber);
  };

  async function getMissionDetails() {
    setIsLoading(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/getMissionDetails`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ reference: reference }),
        }
      );

      const result = await response.json();

      if (response == null) {
        return;
      } else {
        setSector(result.data.sector);
        setFrom(result.data.countryFrom);
        setTo(result.data.countryTo);
        setType(result.data.type);
        setNumberOfEmployees(result.data.numberOfEmployees);
        setStart(result.data.startDate);
        setEnd(result.data.endDate);
        setPersonInCharge(result.data.personInCharge);
        setLodgingId(result.data.lodgingId);

        await getLodgingDetails();

        return JSON.stringify(result.data.missionId);
      }
    } catch (error) {
      console.log('Erreur', error);
    }
  }

  useEffect(() => {
    async function exec() {
      await getMissionDetails();
    }
    exec().then(() => {
      setIsLoading(false);
    });
  }, [lodgingId]);

  const formattedDate = (dataString: string): string => {
    let dateObject = new Date(dataString);

    let day = dateObject.getDate();
    let month = dateObject.getMonth() + 1;
    let year = dateObject.getFullYear();

    let formattedDate = `${day.toString().padStart(2, '0')}/${month
      .toString()
      .padStart(2, '0')}/${year}`;

    return formattedDate;
  };

  const updateDetails = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/updateMission/details`,
        {
          method: 'PATCH',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            reference: reference,
            startDate: start,
            endDate: end,
            numberOfEmployees: numberOfEmployees,
          }),
        }
      );

      const result = await response.json();

      return result.data;
    } catch (error) {
      console.log('Erreur', error);
    }
  };

  const execUpdateDetails = async () => {
    setIsLoading(true);

    const result = await updateDetails();

    return result;
  };

  const handleClickSaveDetails = () => {
    if (
      start == undefined ||
      end == undefined ||
      numberOfEmployees == undefined
    ) {
      setIsErrorHidden(false);
    } else {
      setIsErrorHidden(true);
      execUpdateDetails();
      setIsLoading(false);
      //setGlobalInformationEditPopupIsVisible(false);
      window.location.reload();
    }
  };

  const [lodgingStart, setLodgingStart] = useState<string>();
  const [lodgingEnd, setLodgingEnd] = useState<string>();
  const [lodgingType, setLodgingType] = useState<string>();
  const [lodgingMainAddress, setLodgingMainAddress] = useState<string>();
  const [lodgingAdditionalAddress, setLodgingAdditionalAddress] =
    useState<string>();
  const [lodgingPostalCode, setLodgingPostalCode] = useState<string>();
  const [lodgingCity, setLodgingCity] = useState<string>();
  const [lodgingCountry, setLodgingCountry] = useState<string>();

  async function getLodgingDetails() {
    setIsLoading(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/getLodgingDetails`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ id: lodgingId }),
        }
      );

      const result = await response.json();

      if (response == null) {
        return;
      } else {
        setLodgingStart(result.data.startDate);
        setLodgingEnd(result.data.endDate);
        setLodgingType(result.data.type);

        await getLodgingAddress(result.data.addressId);

        return JSON.stringify(result.data.lodgingId);
      }
    } catch (error) {
      console.log('Erreur', error);
    }
  }

  async function getLodgingAddress(id: number) {
    setIsLoading(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/getAddress`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ id: id }),
        }
      );

      const result = await response.json();

      if (response == null) {
        return;
      } else {
        setLodgingMainAddress(result.data.mainAddress);
        setLodgingAdditionalAddress(result.data.additionalAddress);
        setLodgingPostalCode(result.data.postalCode);
        setLodgingCity(result.data.city);
        setLodgingCountry(result.data.country);

        return JSON.stringify(result.data.addressId);
      }
    } catch (error) {
      console.log('Erreur', error);
    }
  }

  return (
    <>
      {(isLoading && <Loader />) ||
        (!isLoading && (
          <>
            <div className='missionDetailsContainer'>
              <div className='informationContainer'>
                <div
                  className='takeInChargeButtonContainer'
                  hidden={!adminOrOperator}
                >
                  <button
                    className='takeInChargeButton'
                    onClick={handleClickTakeInCharge}
                  >
                    {t('globalStrings.takeInCharge')}
                  </button>
                </div>
                <div className='globalAndOperatorInformationContainer'>
                  <div className='globalInformationContainer'>
                    <h4>
                      {t('missionDetailsPageStrings.globalInformationTitle')}
                    </h4>
                    <div className='globalDetailsText'>
                      <div className='textArea'>
                        <p>
                          <b>Reference: </b>
                          {reference}
                        </p>
                        <p>
                          <b>Sector: </b>
                          {sector == 'Construction'
                            ? t('orderPageStrings.missionSector.construction')
                            : t('orderPageStrings.missionSector.other')}
                        </p>
                        <p>
                          <b>From: </b>
                          {from}
                        </p>
                        <p>
                          <b>To: </b>
                          {to}
                        </p>
                        <p>
                          <b>Type: </b>
                          {type == 'Custom-made'
                            ? t('orderPageStrings.missionType.customMade')
                            : t('orderPageStrings.missionType.tranquility')}
                        </p>
                      </div>
                      <div className='textArea'>
                        <p>
                          <b>Number of employees: </b>
                          {numberOfEmployees}
                        </p>
                        <p>
                          <b>Start date: </b>
                          {formattedDate(start)}
                        </p>
                        <p>
                          <b>End date: </b>
                          {formattedDate(end)}
                        </p>
                        <p>
                          <b>Person in charge: </b>
                          {personInCharge}
                        </p>
                      </div>
                    </div>
                    <EditButton
                      popupVisible={globalInformationEditPopupIsVisible}
                      myRef={globalInformationButtonRef}
                      popupRef={globalInformationEditPopupRef}
                      title={t(
                        'popupStrings.missionDetailsPopupStrings.globalTitle'
                      )}
                      action={handleClickEditGlobalInformationButton}
                      children={
                        <>
                          <div ref={childRefGlobal}>
                            <div className='globalInformationPopUp'>
                              <div className='globalInformationPopUpField'>
                                <p>
                                  <b>
                                    {t(
                                      'popupStrings.missionDetailsPopupStrings.startDate'
                                    )}
                                  </b>
                                </p>
                                <DatePicker
                                  value={start || ''}
                                  className='globalInformationSelectorDate'
                                  format='dd/MM/yyyy'
                                  onChange={handleStartDateChange}
                                  locale={
                                    currentLanguage.toUpperCase().split('-')[0]
                                  }
                                />
                              </div>
                              <div className='globalInformationPopUpField'>
                                <p>
                                  <b>
                                    {t(
                                      'popupStrings.missionDetailsPopupStrings.endDate'
                                    )}
                                  </b>
                                </p>
                                <DatePicker
                                  value={end || ''}
                                  className='globalInformationSelectorDate'
                                  format='dd/MM/yyyy'
                                  onChange={handleEndDateChange}
                                  locale={
                                    currentLanguage.toUpperCase().split('-')[0]
                                  }
                                />
                              </div>
                              <div className='globalInformationPopUpField'>
                                <p>
                                  <b>
                                    {t(
                                      'popupStrings.missionDetailsPopupStrings.numberOfEmployees'
                                    )}
                                  </b>
                                </p>
                                <select
                                  id='select'
                                  ref={numberOfEmployeesSelectorRef}
                                  className='globalInformationSelectorDate'
                                  onClick={() =>
                                    setIsOpenEmployees(!isOpenEmployees)
                                  }
                                  value={numberOfEmployees || ''}
                                  onChange={handleNumberOfEmployeesChange}
                                >
                                  <option
                                    key='defaultNumberOfEmployees'
                                    value=''
                                  >
                                    {t('---')}
                                  </option>
                                  {numbers.map((number) => (
                                    <option key={number} value={number}>
                                      {number}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className='space'></div>
                          <div className='companyError' hidden={isErrorHidden}>
                            <p>{t('orderPageStrings.error')}</p>
                          </div>
                          <div className='saveButtonContainer'>
                            <button
                              className='saveButton'
                              onClick={handleClickSaveDetails}
                            >
                              <p>{t('popupStrings.saveText')}</p>
                              <img
                                src={saveLogo}
                                alt={t('popupStrings.saveLogo')}
                                className='saveLogo'
                              />
                            </button>
                          </div>
                        </>
                      }
                    />
                  </div>
                  <div className='operatorContainer'>
                    <div className='textArea'>
                      <h4>{t('missionDetailsPageStrings.inspectionTitle')}</h4>
                      {inspection ? (
                        <p>
                          {t('missionDetailsPageStrings.inspectionSecondText')}
                        </p>
                      ) : (
                        <p>
                          {t('missionDetailsPageStrings.inspectionFirstText')}
                        </p>
                      )}
                      <div className='textAreaButtonContainer'>
                        {inspection ? (
                          <button
                            className='textAreaButton'
                            onClick={handleClickGoToInspection}
                          >
                            {t(
                              'missionDetailsPageStrings.inspectionSecondButton'
                            )}
                          </button>
                        ) : (
                          <button
                            className='textAreaButton'
                            onClick={handleClickAddInspection}
                          >
                            {t(
                              'missionDetailsPageStrings.inspectionFirstButton'
                            )}
                          </button>
                        )}
                      </div>
                      <h4>{t('missionDetailsPageStrings.payementTitle')}</h4>
                      {paid ? (
                        <p>
                          {t('missionDetailsPageStrings.payementSecondText')}
                        </p>
                      ) : (
                        <p>
                          {t('missionDetailsPageStrings.payementFirstText')}
                        </p>
                      )}
                      <div className='textAreaButtonContainer'>
                        <button className='textAreaButton' hidden={paid}>
                          {t('missionDetailsPageStrings.payementButton')}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='otherInformationContainer'>
                  <div className='employeesInformation'>
                    <h4>
                      {t('missionDetailsPageStrings.employeesInformationTitle')}
                    </h4>
                    <div className='textArea'>
                      <i>Non fonctionnel pour l'instant</i>
                    </div>
                    <EditButton
                      popupVisible={employeesEditPopupIsVisible}
                      myRef={employeesButtonRef}
                      popupRef={employeesEditPopupRef}
                      title={t(
                        'popupStrings.missionDetailsPopupStrings.employeesTitle'
                      )}
                      action={() => {}} //handleClickEditEmployeesButton}
                      children={
                        <div ref={childRefEmployees} className='container'>
                          <div className='employeesMissionDetailsHeader'>
                            <h4>Employees' list</h4>
                            <button
                              ref={addButtonRef}
                              className='editButton'
                              onClick={addEmployee}
                            >
                              <p>{t('Add an employee')}</p>
                              <img
                                src={addLogo}
                                alt='Add logo'
                                className='addLogo'
                              />
                            </button>
                          </div>
                          <div className='employeesMissionDetailsList'></div>
                        </div>
                      }
                    />
                  </div>
                  <div className='lodgingInformation'>
                    <h4>
                      {t('missionDetailsPageStrings.lodgingInformationTitle')}
                    </h4>
                    <div className='lodgingText'>
                      <div className='textArea'>
                        <p>
                          <b>Main address: </b>
                          {lodgingMainAddress}
                        </p>
                        <p>
                          <b>Additional address: </b>
                          {lodgingAdditionalAddress == '' ? (
                            <i>Not defined</i>
                          ) : (
                            lodgingAdditionalAddress
                          )}
                        </p>
                        <p>
                          <b>Postal code: </b>
                          {lodgingPostalCode}
                        </p>
                        <p>
                          <b>City: </b>
                          {lodgingCity}
                        </p>
                        <p>
                          <b>Country: </b>
                          {lodgingCountry}
                        </p>
                      </div>
                      <div className='textArea'>
                        <p>
                          <b>Start date: </b>
                          {formattedDate(lodgingStart)}
                        </p>
                        <p>
                          <b>End date: </b>
                          {formattedDate(lodgingEnd)}
                        </p>
                        <p>
                          <b>Type: </b>
                          {lodgingType}
                        </p>
                      </div>
                    </div>
                    <EditButton
                      popupVisible={lodgingEditPopupIsVisible}
                      myRef={lodgingButtonRef}
                      popupRef={lodgingEditPopupRef}
                      title={t(
                        'popupStrings.missionDetailsPopupStrings.lodgingTitle'
                      )}
                      action={() => {}} //handleClickEditLodgingButton}
                      children={
                        <LodgingMissionDetails
                          ref={childRefLodging}
                          defaultStartDate={lodgingStart}
                          defaultEndDate={lodgingEnd}
                          defaultType={lodgingType}
                          defaultMainAddress={lodgingMainAddress}
                          defaultAdditionalAddress={lodgingAdditionalAddress}
                          defaultPostalCode={lodgingPostalCode}
                          defaultCity={lodgingCity}
                          defaultCountry={lodgingCountry}
                        />
                      }
                    />
                  </div>
                </div>
              </div>

              {inspectionPopupVisible ? (
                <Popup
                  myRef={inspectionPopupRef}
                  title={t('createInspection.title')}
                  onClickClose={handleClickCloseInspectionPopup}
                >
                  {<CreateInspection id={id} reference={reference} />}
                </Popup>
              ) : null}
            </div>
          </>
        ))}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: selectors.auth.userSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(MissionDetails);
