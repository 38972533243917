/**
 * Dependencies.
 */
import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

/**
 * Resources
 */
import './orderForm.css';
import { currentLanguage } from '../../../../views';
import countriesEN from '../../assets/json/countriesEN.json';
import countriesFR from '../../assets/json/countriesFR.json';
import TextInput from '../textInput/textInput';
import CountrySelector from '../countriesSelector/countriesSelector';

/**
 * LocationFormProps interface defines the structure of props
 * expected by the LocationForm component.
 */
interface LocationFormProps extends WithTranslation {}

/**
 * LocationForm component displays a part of OrderForm,
 * that gives user possibility to precise the place where
 * employees will be working.
 */
class LocationForm extends Component<LocationFormProps> {
  private locationCountries = [];

  componentDidMount(): void {
    if (currentLanguage == 'fr') {
      this.locationCountries = countriesFR.sort((a: any, b: any) => {
        return a.name.localeCompare(b.name);
      });
    } else {
      this.locationCountries = countriesEN.sort((a: any, b: any) => {
        return a.name.localeCompare(b.name);
      });
    }
  }

  render() {
    /**
     * Extract the `t` function from `useTranslation` hook.
     */
    const { t } = this.props;

    let name: string = window.sessionStorage.getItem('locationName');
    let mainAddress: string = window.sessionStorage.getItem(
      'locationMainAddress'
    );
    let additionalAddress: string = window.sessionStorage.getItem(
      'locationAdditionalAddress'
    );
    let postalCode: string =
      window.sessionStorage.getItem('locationPostalCode');
    let city: string = window.sessionStorage.getItem('locationCity');
    let country: string = window.sessionStorage.getItem('locationCountry');

    function handleLocationNameChange(value: string) {
      window.sessionStorage.setItem('locationName', value);
    }

    function handleLocationMainAddressChange(value: string) {
      window.sessionStorage.setItem('locationMainAddress', value);
    }

    function handleLocationAdditionalAddressChange(value: string) {
      window.sessionStorage.setItem('locationAdditionalAddress', value);
    }

    function handleLocationPostalCodeChange(value: string) {
      window.sessionStorage.setItem('locationPostalCode', value);
    }

    function handleLocationCityChange(value: string) {
      window.sessionStorage.setItem('locationCity', value);
    }

    function handleSelectChangeLocationCountry(
      event: React.ChangeEvent<HTMLSelectElement>
    ) {
      const newSelectedOptionLocationCountry = event.target.value;

      window.sessionStorage.setItem(
        'locationCountry',
        newSelectedOptionLocationCountry
      );
    }

    return (
      <div className='orderFormLocation'>
        <i>{t('orderPageStrings.location.details')}</i>
        <div className='subBlocks'>
          <TextInput
            classname='locationName'
            placeholder={t('orderPageStrings.location.name')}
            initialValue={name}
            onSave={handleLocationNameChange}
          />
        </div>
        <div className='locationAddressInformation'>
          <div className='subBlocks'>
            <TextInput
              classname='locationMainAddress'
              placeholder={t('orderPageStrings.location.address')}
              initialValue={mainAddress}
              onSave={handleLocationMainAddressChange}
            />
          </div>
        </div>
        <div className='locationAddressInformation'>
          <div className='subBlocksAdditional'>
            <TextInput
              classname='locationAdditionalAddress'
              placeholder={t(
                'popupStrings.missionDetailsPopupStrings.additionalAddress'
              )}
              initialValue={additionalAddress}
              onSave={handleLocationAdditionalAddressChange}
            />
          </div>
          <div className='subBlocksPostal'>
            <TextInput
              classname='locationPostalCode'
              placeholder={t(
                'popupStrings.missionDetailsPopupStrings.postalCode'
              )}
              initialValue={postalCode}
              onSave={handleLocationPostalCodeChange}
            />
          </div>
        </div>
        <div className='locationAddressInformation'>
          <div className='subBlocksBottom'>
            <TextInput
              classname='locationCity'
              placeholder={t('popupStrings.missionDetailsPopupStrings.city')}
              initialValue={city}
              onSave={handleLocationCityChange}
            />
          </div>
          <div className='subBlocksBottom'>
            <CountrySelector
              divClassName='locationCountry'
              selectorClassName='locationSelectorCountry'
              selectorValue={country}
              selectorOnChange={handleSelectChangeLocationCountry}
              countriesList={this.locationCountries}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(LocationForm);
