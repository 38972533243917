import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import tippy from 'tippy.js';

import tooltip from '../../assets/images/logos/tooltip.png';

import 'tippy.js/dist/tippy.css';
import './orderForm.css';

interface MissionTypeProperties {
  setCount: React.Dispatch<React.SetStateAction<number>>;
}

const MissionType: React.FC<MissionTypeProperties> = ({ setCount }) => {
  const { t } = useTranslation();

  const handleIncrement = () => {
    setCount((prevCount) => prevCount + 1);
  };

  const tooltipRef = useRef(null);

  const options = [
    {
      id: '0',
      key: 'customMade',
      label: t('orderPageStrings.missionType.customMade'),
      tooltip: t(
        'orderPageStrings.missionType.orderTooltipMissionType.customMadeTooltip'
      ),
    },
    {
      id: '1',
      key: 'tranquility',
      label: t('orderPageStrings.missionType.tranquility'),
      tooltip: t(
        'orderPageStrings.missionType.orderTooltipMissionType.tranquilityTooltip'
      ),
    },
  ];

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    window.sessionStorage.setItem('selectedOption', event.target.value);
    window.sessionStorage.setItem('selectedOptionId', event.target.id);

    tooltipConfig();

    if (
      window.sessionStorage.getItem('selectedOption')!.replace(/"/g, '') !=
      'customMade'
    ) {
      window.sessionStorage.removeItem('sipsiSelectedOption');
      window.sessionStorage.removeItem('constructionCardSelectedOption');
    }

    handleIncrement();
  };

  function tooltipConfig() {
    if (window.sessionStorage.getItem('selectedOptionId') == '0') {
      if (tooltipRef.current) {
        tippy(tooltipRef.current, {
          content: options[0].tooltip,
          placement: 'right',
          arrow: true,
        });
      }
    } else {
      if (tooltipRef.current) {
        tippy(tooltipRef.current, {
          content: options[1].tooltip,
          placement: 'right',
          arrow: true,
        });
      }
    }

    handleIncrement();
  }

  useEffect(() => {
    tooltipConfig();
  }, []);

  return (
    <div className='orderDetailsMissionType'>
      <pre>
        <p id='obligatoire'>* </p>
      </pre>
      <pre>
        <p>{t('orderPageStrings.missionType.title')}</p>
      </pre>
      {options.map((option) => (
        <div key={option.key} className='orderDetailsRadioButton'>
          <label key={option.key}>
            <input
              id={option.id}
              type='radio'
              name='options'
              value={option.key}
              onChange={handleOptionChange}
              checked={
                window.sessionStorage.getItem('selectedOptionId') == option.id
              }
            />
            {option.label}
          </label>
        </div>
      ))}
      <img
        src={tooltip}
        alt='tooltipButton'
        className='tooltip'
        ref={tooltipRef}
      />
    </div>
  );
};

export default MissionType;
